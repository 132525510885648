import {
  SERVICEFORM_REQUEST,
  SERVICEFORM_SUCCESS,
  SERVICEFORM_FAILURE,
  FORMSPECIFICDATA_FAILURE,
  FORMSPECIFICDATA_REQUEST,
  FORMSPECIFICDATA_SUCCESS,
  SAVEFIRFORM_FAILURE,
  SAVEFIRFORM_REQUEST,
  SAVEFIRFORM_SUCCESS,
  RESET_DEFAULT,
  DOWNLOADING_PDF,
  SAVE_PDF,
  ERROR_PDF_DOWNLOAD,
  END_TRIP_DATE_REQUEST,
  END_TRIP_DATE_SUCCESS,
  END_TRIP_DATE_FAILURE,
  MALFUNCTION_DATE_REQUEST,
  MALFUNCTION_DATE_SUCCESS,
  MALFUNCTION_DATE_FAILURE,
} from './constants';
import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';

export const getServiceForm = (
  accessToken,
  status,
  pageNumber,
  pageSize,
  followUpCallId,
) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetPortalService?status=${status}&PageNumber=${pageNumber}&PageSize=${pageSize}&FollowUpCallId=${followUpCallId}`,
    method: 'GET',
    types: [SERVICEFORM_REQUEST, SERVICEFORM_SUCCESS, SERVICEFORM_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getFormSpecificData = (
  accessToken,
  serviceDetailId,
  formId,
  formName,
) => {
  return createAction({
    endpoint: `${API_URL}/Service/${formName}?ServiceDetailId=${serviceDetailId}&FormId=${formId}`,
    method: 'GET',
    types: [
      FORMSPECIFICDATA_REQUEST,
      FORMSPECIFICDATA_SUCCESS,
      FORMSPECIFICDATA_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const saveFirForm = (payload, accessToken) => {
  // console.log('L53 data to be update-->', JSON.stringify(payload));
  return createAction({
    endpoint: `${API_URL}/Service/EditFirForm`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [SAVEFIRFORM_REQUEST, SAVEFIRFORM_SUCCESS, SAVEFIRFORM_FAILURE],
  });
};

export const resetToDefaultFIRFlags = () => ({
  type: RESET_DEFAULT,
});

export const downloadPDF = (accessToken, serviceDetailId, formName) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetPdfAsByteArray/${serviceDetailId}/${formName}`,
    method: 'GET',
    types: [DOWNLOADING_PDF, SAVE_PDF, ERROR_PDF_DOWNLOAD],
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/pdf',
    },
  });
};

export const getEndTripDate = (accessToken, serviceDetailId) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetEndTripDate/${serviceDetailId}`,
    method: 'GET',
    types: [
      END_TRIP_DATE_REQUEST,
      END_TRIP_DATE_SUCCESS,
      END_TRIP_DATE_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getFailureDate = (accessToken, serviceDetailId) => {
  return createAction({
    endpoint: `${API_URL}/Service/GetFailureDate/${serviceDetailId}`,
    method: 'GET',
    types: [
      MALFUNCTION_DATE_REQUEST,
      MALFUNCTION_DATE_SUCCESS,
      MALFUNCTION_DATE_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
