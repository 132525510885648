export const SERVICEFORMCLOSE_REQUEST = 'SERVICEFORMCLOSE_REQUEST';
export const SERVICEFORMCLOSE_SUCCESS = 'SERVICEFORMCLOSE_SUCCESS';
export const SERVICEFORMCLOSE_FAILURE = 'SERVICEFORMCLOSE_FAILURE';
export const FORCECLOSEREQUEST_REQUEST = 'FORCECLOSEREQUEST_REQUEST';
export const FORCECLOSEREQUEST_SUCCESS = 'FORCECLOSEREQUEST_SUCCESS';
export const FORCECLOSEREQUEST_FAILURE = 'FORCECLOSEREQUEST_FAILURE';
export const SERVICEFAILEDCALLS_REQUEST = 'SERVICEFAILEDCALLS_REQUEST';
export const SERVICEFAILEDCALLS_SUCCESS = 'SERVICEFAILEDCALLS_SUCCESS';
export const SERVICEFAILEDCALLS_FAILURE = 'SERVICEFAILEDCALLS_FAILURE';
export const SERVICEFAILEDCALLSCount_REQUEST =
  'SERVICEFAILEDCALLSCount_REQUEST';
export const SERVICEFAILEDCALLSCount_SUCCESS =
  'SERVICEFAILEDCALLSCount_SUCCESS';
export const SERVICEFAILEDCALLSCount_FAILURE =
  'SERVICEFAILEDCALLSCount_FAILURE';
