import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import COLORS from '../../constants/style/colors';

import {
  Alert,
  Modal,
  Form,
  Collapse,
  Spin,
  Input,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Layout,
  Space,
  DatePicker,
  Select,
} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import _, {template} from 'lodash';
import {Box, Paper, Tabs, Tab, Typography} from '@material-ui/core';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {
  getServiceFormSpecificData,
  getIsFetchingFormData,
  isSavingFIRForm,
  hasSavedFIRForm,
  failureFIRFormSave,
  downloadedPDFData,
  downloadingPDFData,
  //getEndTripDateSelector,
  getFailureDateDateSelector,
} from '../../modules/serviceForms/selectors';
import {
  getFormSpecificData,
  saveFirForm,
  resetToDefaultFIRFlags,
  downloadPDF,
  //getEndTripDate,
  getFailureDate,
} from '../../modules/serviceForms/action';
import base64js from 'base64-js';
import {IMAGE, SIGNATURE, INPUT_DATE, SELECT} from './constants';
const {Panel} = Collapse;
const {Option} = Select;
const TabPanel = (props) => {
  const {children, value, index, ...other} = props;
  return (
    <div
      aria-labelledby={`scrollable-auto-tab-${index}`}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ServiceModal = ({visible, onCancel, onCreate, data}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const authUser = useSelector(getAuthUserData);
  const mainData = useSelector(getServiceFormSpecificData);
  const [formData, setFormData] = useState(null);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isFetchingFormData = useSelector(getIsFetchingFormData);
  const firFormSaveRequest = useSelector(isSavingFIRForm);
  const firFormSaveSuccess = useSelector(hasSavedFIRForm);
  const firFormSaveFailure = useSelector(failureFIRFormSave);
  const [pdfDownloadClick, setPdfDownloadClick] = useState(false);
  const pdfData = useSelector(downloadedPDFData);
  const [value, setValue] = React.useState(0); //tab changing value
  const downloading = useSelector(downloadingPDFData);
  const [fileName, setFileName] = useState('');
  //const endTripDT = useSelector(getEndTripDateSelector);
  const failureDT = useSelector(getFailureDateDateSelector);

  let alertMessage = useRef(null);
  const closeModal = () => {
    setValue(0);
    setFormData(null);
    setDisabled(true);
    onCancel();
  };
  useEffect(() => {
    if (firFormSaveSuccess && submitting) {
      dispatch(resetToDefaultFIRFlags()); // reset flags to default fro consequent actions
      alertMessage.current = 'success';
      setSubmitting(false);
    }
    if (firFormSaveRequest) {
      setDisabled(true);
      setSubmitting(true);
    }
    if (firFormSaveFailure && submitting) {
      dispatch(resetToDefaultFIRFlags());
      alertMessage.current = 'error';
      setDisabled(false);
      setSubmitting(false);
    }
  }, [
    firFormSaveFailure,
    firFormSaveRequest,
    firFormSaveSuccess,
    submitting,
    dispatch,
  ]);

  useEffect(() => {
    if (mainData != undefined) {
      let tmpData = JSON.parse(JSON.stringify(mainData));

      tmpData.sections.forEach((eachSection) => {
        if (eachSection.sectionName === 'descriptionOfService') {
          eachSection.fields.forEach((eachField) => {
            switch (eachField.name) {
              case 'descOfServiceDone1':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate1',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone2':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate2',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone3':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate1',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone4':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate4',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone5':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate5',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone6':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate6',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone7':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate7',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone8':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate8',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone9':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate9',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone10':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate10',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone11':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate11',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone12':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate12',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone13':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate13',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone14':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate14',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone15':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate15',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone16':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate16',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone17':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate17',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone18':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate18',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone19':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate19',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone20':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate20',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone21':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate21',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone22':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate22',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone23':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate23',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone24':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate24',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone25':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate25',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone26':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate26',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone27':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate27',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone28':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate28',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone29':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate29',
                    )
                  ].meta.required = true;
                }
                break;
              case 'descOfServiceDone30':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === 'txtDate30',
                    )
                  ].meta.required = true;
                }
                break;
            }
          });
        }
        if (eachSection.sectionName === 'image') {
          eachSection.fields.forEach((eachField) => {
            let index = tmpData.sections.findIndex(
              (item) => item.sectionName === 'imageDescription',
            );

            switch (eachField.name) {
              case 'imgAdditional1':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  tmpData.sections[index].fields[
                    tmpData.sections[index].fields.findIndex(
                      (item) => item.name === 'imgAdditional1Description',
                    )
                  ].meta.required = true;
                }
                break;
              case 'imgAdditional2':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  tmpData.sections[index].fields[
                    tmpData.sections[index].fields.findIndex(
                      (item) => item.name === 'imgAdditional2Description',
                    )
                  ].meta.required = true;
                }
                break;
              case 'imgAdditional3':
                if (
                  eachSection.fields[
                    eachSection.fields.findIndex(
                      (item) => item.name === eachField.name,
                    )
                  ].response
                ) {
                  tmpData.sections[index].fields[
                    tmpData.sections[index].fields.findIndex(
                      (item) => item.name === 'imgAdditional3Description',
                    )
                  ].meta.required = true;
                }
                break;
            }
          });
        }
      });

      setFormData(tmpData);
    } else {
      setFormData(mainData);
    }
  }, [mainData]);

  useEffect(() => {
    if (data.length !== 0) {
      dispatch(
        getFormSpecificData(
          authUser.accessToken,
          data.serviceRequestId,
          0,
          Object.keys(data.serviceDetail.formDetails)[0], // default fetch safety form
        ),
      );
      //dispatch(getEndTripDate(authUser.accessToken, data.serviceRequestId));
      dispatch(getFailureDate(authUser.accessToken, data.serviceRequestId));
    }
  }, [authUser.accessToken, data, dispatch]);

  const handleChange = (event, newValue) => {
    const formDetailKey = Object.keys(data.serviceDetail.formDetails).filter(
      (e) => e !== 'consiteFormDetails',
    )[newValue];

    const finalFormDetailKey =
      formDetailKey === 'faultCodeDetails'
        ? 'faultCodesDetailsPortal'
        : formDetailKey;

    dispatch(
      getFormSpecificData(
        authUser.accessToken,
        data.serviceRequestId,
        0,
        finalFormDetailKey,
      ),
    );
    setValue(newValue);
  };

  const handleDisabled = () => {
    if (formData.name === 'FIR Form Details') {
      setDisabled(!disabled);
    }
  };

  const handleInputChange = (value, section, field) => {
    // let tmpData = _.cloneDeep(formData);
    let tmpData = JSON.parse(JSON.stringify(formData));
    tmpData.sections.forEach((eachSection) => {
      if (eachSection.sectionDisplayOrder === section.sectionDisplayOrder) {
        eachSection.fields.forEach((eachField) => {
          if (eachField.id === field.id) {
            eachField.response = value;
            switch (field.name) {
              case 'descOfServiceDone1':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate1',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone2':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate2',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone3':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate1',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone4':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate4',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone5':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate5',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone6':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate6',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone7':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate7',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone8':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate8',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone9':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate9',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone10':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate10',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone11':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate11',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone12':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate12',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone13':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate13',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone14':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate14',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone15':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate15',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone16':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate16',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone17':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate17',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone18':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate18',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone19':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate19',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone20':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate20',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone21':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate21',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone22':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate22',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone23':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate23',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone24':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate24',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone25':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate25',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone26':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate26',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone27':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate27',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone28':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate28',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone29':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate29',
                  )
                ].meta.required = true;
                break;
              case 'descOfServiceDone30':
                eachSection.fields[
                  eachSection.fields.findIndex(
                    (item) => item.name === 'txtDate30',
                  )
                ].meta.required = true;
                break;
            }
          }
        });
      }
    });
    setFormData(tmpData);
    setAllowSubmit(true);
  };

  const handleSubmitForm = () => {
    let errosArray = [];
    if (formData) {
      formData.sections.forEach((eachSection) => {
        eachSection.fields.forEach((eachField) => {
          if (eachField.meta.required && !eachField.response) {
            errosArray.push(eachField.name);
          }
        });
      });
    }
    console.log(JSON.stringify(errosArray));
    if (errosArray.length === 0) {
      let responseArray = formData.sections.map((section) => {
        return section.fields.map((field) => {
          return {id: field.id, response: field.response};
        });
      });
      let payload = {
        serviceRequestId: data.serviceRequestId,
        fields: responseArray.flatMap((e) => e),
      };

      dispatch(saveFirForm(payload, authUser.accessToken));
      setAllowSubmit(false);
    }
  };

  const renderFieldComponent = (section, field) => {
    let fieldComponent = null;

    let minDateForValidation = null;

    if (field.name === 'dateOfRepair') {
      minDateForValidation = failureDT;
    } else if (field.name === 'recommissionHRM') {
      minDateForValidation = failureDT;
    }

    switch (field.meta.type) {
      case IMAGE:
        fieldComponent = (
          <Box
            bgcolor="background.paper"
            boxShadow={3}
            m={4}
            style={{width: '23rem', height: '23rem'}}>
            <div
              style={{
                borderBottom: '1pt solid black',
                color: 'white',
                backgroundColor: COLORS.brandColor.current,
              }}>
              <b>{field.label}</b>
            </div>
            <img
              alt={''}
              height="94%"
              src={`data:image/png;base64,${field.response}`}
              width="100%"
            />
          </Box>
        );
        break;
      case SIGNATURE:
        fieldComponent = (
          <Box
            bgcolor="background.paper"
            boxShadow={3}
            style={{width: '24rem', height: '20rem'}}>
            <div
              style={{
                borderBottom: '1pt solid black',
                color: 'white',
                backgroundColor: COLORS.brandColor.current,
              }}>
              <b>{field.label}</b>
            </div>
            <img
              alt={''}
              src={`data:image/png;base64,${field.response}`}
              width="100%"
            />
          </Box>
          // </div>
        );
        break;
      case INPUT_DATE:
        fieldComponent = (
          <div>
            <Row>
              <Col className="tdHeader" span={24}>
                {field.label}{' '}
                {field.meta.required === true ? (
                  <span style={{color: 'red'}}>*</span>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space direction="vertical">
                  <DatePicker
                    clearIcon={null}
                    defaultValue={
                      field.response !== ''
                        ? moment(field.response)
                        : moment(new Date())
                    }
                    disabled={
                      field.meta.readOnly ? field.meta.readOnly : disabled
                    }
                    disabledDate={(current) => {
                      return minDateForValidation === null
                        ? moment(
                            field.name === 'dateOfCommissioning'
                              ? failureDT
                              : field.meta.maxDateValue,
                          ).add(1, 'days') <= current
                        : moment(minDateForValidation).add(0, 'days') >=
                            current;
                    }}
                    format={'DD/MM/YYYY'}
                    onChange={(event) => {
                      handleInputChange(event._d, section, field);
                    }}
                    style={{width: '100%', marginRight: '10px'}}
                    value={field.response !== '' ? moment(field.response) : ''}
                  />
                </Space>
                <br />
                {field.meta.required && !field.response && (
                  <span style={{color: 'red'}}>{field.label} is required</span>
                )}
                {/* <Input
                  disabled={
                    field.meta.readOnly ? field.meta.readOnly : disabled
                  }
                  onChange={(event) => {
                    handleInputChange(event.target.value, section, field);
                  }}
                  value={moment(field.response).format('DD/MM/YYYY hh:mm A')}
                /> */}
              </Col>
            </Row>
          </div>
        );
        break;
      case SELECT:
        fieldComponent = (
          <div>
            <Row>
              <Col className="tdHeader" span={24}>
                {field.label}{' '}
                {field.meta.required === true ? (
                  <span style={{color: 'red'}}>*</span>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  defaultValue={`Select ${field.label}`}
                  disabled={
                    field.meta.readOnly ? field.meta.readOnly : disabled
                  }
                  onChange={(value) => {
                    console.log('select ', value);
                    handleInputChange(value, section, field);
                  }}
                  value={
                    field.response === ''
                      ? `Select ${field.label}`
                      : field.response
                  }>
                  {field.meta.options?.map((p) => (
                    <Option value={p}>{p}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
        );
        break;
      default:
        fieldComponent = (
          <div>
            <Row>
              <Col className="tdHeader" span={24}>
                {field.label}{' '}
                {field.meta.required === true ? (
                  <span style={{color: 'red'}}>*</span>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  disabled={
                    field.meta.readOnly ? field.meta.readOnly : disabled
                  }
                  onChange={(event) => {
                    handleInputChange(event.target.value, section, field);
                  }}
                  value={field.response}
                />
                <br />
                {field.meta.required && !field.response && (
                  <span style={{color: 'red'}}>{field.label} is required</span>
                )}
              </Col>
            </Row>
          </div>
        );
        break;
    }
    return fieldComponent;
  };

  const handleDownloadPDF = () => {
    dispatch(
      downloadPDF(
        authUser.accessToken,
        data.serviceRequestId,
        Object.keys(data.serviceDetail.formDetails).filter(
          (e) => e !== 'consiteFormDetails',
        )[value],
      ),
    );
    setFileName(
      Object.keys(data.serviceDetail.formDetails).filter(
        (e) => e !== 'consiteFormDetails',
      )[value],
    );
    setPdfDownloadClick(!pdfDownloadClick);
  };

  useEffect(() => {
    if (pdfData && pdfDownloadClick && !downloading) {
      const blob = new Blob([base64js.toByteArray(pdfData.pdfArray)], {
        type: 'application/pdf',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName + '.pdf';
      link.click();
      setPdfDownloadClick(!pdfDownloadClick);
    }
  }, [pdfData, pdfDownloadClick, fileName, downloading]);

  if (data.length === 0) {
    return <div />;
  }
  // console.log('formData -> ', data.serviceDetail.formDetails);
  return (
    <Modal
      cancelText="Close"
      footer={null}
      onCancel={closeModal}
      title="Service Form Details"
      visible={visible}
      width={1500}>
      <StyledHeader
        style={{position: 'sticky', top: 0, zIndex: 1000, height: '70pt'}}>
        {alertMessage.current ? (
          <Alert
            afterClose={() => {
              console.log('here');
              alertMessage.current = null;
            }}
            banner
            closable
            message={
              alertMessage.current === 'success'
                ? 'FORM SAVED SUCCESSFULLY'
                : 'ERROR! CANT SAVE FORM'
            }
            type={alertMessage.current}
          />
        ) : null}
        <Button
          disabled={
            formData?.name === 'FIR Form Details'
              ? data?.serviceDetail.formDetails.firFormDetails.status !==
                'Completed'
                ? true
                : submitting
                ? true
                : false
              : true
          }
          onClick={handleDisabled}
          shape="round"
          size={10}
          style={{
            backgroundColor:
              formData?.name === 'FIR Form Details'
                ? data?.serviceDetail.formDetails.firFormDetails.status ===
                  'Completed'
                  ? COLORS.brandColor.current
                  : COLORS.textColor.lightGray
                : COLORS.textColor.lightGray,
            color: 'white',
          }}>
          <b>Edit</b>
        </Button>
        {'    '}
        <Button
          disabled={
            _.isEqual(formData, mainData)
              ? true
              : submitting
              ? true
              : !allowSubmit
              ? true
              : false
          }
          onClick={handleSubmitForm}
          shape="round"
          size={10}
          style={{
            backgroundColor: _.isEqual(formData, mainData)
              ? COLORS.textColor.lightGray
              : COLORS.success.background,
            color: 'white',
            display: formData?.name === 'FIR Form Details' ? 'inline' : 'none',
          }}>
          <b>Save Form</b>
        </Button>
        {'    '}
        {submitting ? (
          <b>
            <Spin /> Saving...
          </b>
        ) : null}
        {'    '}
        {formData?.name !== 'Part Recommendation Details' ? (
          <Button
            onClick={handleDownloadPDF}
            shape="round"
            size={10}
            style={{
              backgroundColor: COLORS.brandColor.current,
              color: 'white',
            }}>
            <b>
              <DownloadOutlined /> PDF
            </b>
          </Button>
        ) : null}
        <span style={{padding: '10pt', fontSize: 'large'}}>
          <b>
            {formData?.name === 'FIR Form Details'
              ? data?.serviceDetail.formDetails.firFormDetails.status !==
                'Completed'
                ? 'Cant Edit Form Status Active'
                : null
              : null}
          </b>
        </span>
      </StyledHeader>
      <Paper square>
        <Tabs
          aria-label="disabled tabs example"
          indicatorColor="primary"
          onChange={handleChange}
          scrollButtons="auto"
          textColor="primary"
          value={value}
          variant="scrollable">
          {Object.keys(data.serviceDetail.formDetails)
            .filter((e) => e !== 'consiteFormDetails')
            .map((e) => (
              <Tab
                // disabled={e === 'safetyCheckDetails' ? true : false}
                label={data.serviceDetail.formDetails[e].name}
              />
            ))}
        </Tabs>
        {Object.keys(data.serviceDetail.formDetails)
          .filter((e) => e !== 'consiteFormDetails')
          .map((e, index) => (
            <TabPanel index={index} value={value}>
              {isFetchingFormData ? (
                <div style={spinStyle}>
                  <Spin />
                </div>
              ) : formData?.name === 'Part Recommendation Details' ? (
                formData.previouslyAddedList.map((partsItems, index) => {
                  let newSection = {...formData.sections[0]};
                  newSection.fields.map((fieldElement) => {
                    fieldElement['response'] = partsItems[fieldElement['name']];
                  });
                  // formData.sections.add(newSection);
                  return (
                    <Collapse accordion>
                      <Panel
                        header={newSection.title}
                        key={newSection.sectionDisplayOrder}>
                        <div style={panelStyle.parent}>
                          {/* //=== Conditional Operation for the Part Recommendation */}
                          {newSection.fields.map((field) => {
                            let fieldDisplay = renderFieldComponent(
                              newSection,
                              field,
                            );
                            return field.meta.isHidden === false ? (
                              <div
                                style={
                                  newSection.sectionName ===
                                  'failedComponentDetails'
                                    ? panelStyle.siblingschild
                                    : panelStyle.child
                                }>
                                {fieldDisplay}
                              </div>
                            ) : (
                              ''
                            );
                          })}
                        </div>
                      </Panel>
                    </Collapse>
                  );
                })
              ) : (
                formData?.sections.map((section) => {
                  return (
                    <Collapse accordion>
                      <Panel
                        header={section.title}
                        key={section.sectionDisplayOrder}>
                        <div style={panelStyle.parent}>
                          {/* //=== Conditional Operation for the Part Recommendation */}
                          {section.fields.map((field) => {
                            let fieldDisplay = renderFieldComponent(
                              section,
                              field,
                            );
                            return field.meta.isHidden === false ? (
                              <div
                                style={
                                  section.sectionName ===
                                  'failedComponentDetails'
                                    ? panelStyle.siblingschild
                                    : panelStyle.child
                                }>
                                {fieldDisplay}
                              </div>
                            ) : (
                              ''
                            );
                          })}
                        </div>
                      </Panel>
                    </Collapse>
                  );
                })
              )}
            </TabPanel>
          ))}
      </Paper>
    </Modal>
  );
};
const StyledHeader = styled(Layout.Header)`
  background: ${COLORS.textColor.lightGray};
`;
const spinStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const panelStyle = {
  parent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  child: {
    flex: '1 0 40%',
    margin: '5px',
    height: '100%',
    width: '30%',
  },
  siblingschild: {
    flex: '1 0 24%',
    margin: '5px',
    height: '100%',
    width: '30%',
  },
};
export default ServiceModal;
