import {createSelector} from 'reselect';

export const getServiceForms = ({serviceForms}) => serviceForms;

export const getServiceFormSpecificData = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.formSpecificData,
);

export const getIsFetchingFormData = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.isFetchingFormSpecificData,
);

export const getServiceData = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.dataService?.tableData,
);
export const isDashboardDataFetched = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.hasDashboardFetched,
);
export const isServiceDataFetched = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.hasServiceFetched,
);
export const getFormSpecificData = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.dataDashboardCount,
);
export const isDashboardDataCountFetched = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.hasDashboardCountFetched,
);
export const getServiceTableDataCount = createSelector(
  getServiceForms,
  (getServiceForms) => getServiceForms.dataService?.dataCount,
);

export const isSavingFIRForm = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.isSavingFIRForm,
);

export const hasSavedFIRForm = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.hasSavedFIRForm,
);

export const failureFIRFormSave = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.failureFIRFormSave,
);
export const downloadedPDFData = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.downloadedPDF,
);
export const downloadingPDFData = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.downloadingPDF,
);

export const getEndTripDateSelector = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.endTripDate,
);

export const getFailureDateDateSelector = createSelector(
  getServiceForms,
  (getServiceForm) => getServiceForm.failureDate,
);
