import React, {useEffect, useState} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Table, Col, Row, message, Input, Button, Pagination} from 'antd';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {useSelector, useDispatch} from 'react-redux';
import {
  getServiceFormClose,
  forceCloseService,
  getFailedCallsData,
  getFailedCallsDataCount,
} from '../../modules/serviceFormClose/action';
import {
  isServiceFormForceClosed,
  hasForceCloseService,
  isForceClosingService,
  getServiceFormCloseCsvData,
  getFailedCallsCount,
  getFailedCallData,
  isFailedCallsFetching,
} from '../../modules/serviceFormClose/selectors';
import {serviceCallCloseColumns} from '../../data/serviceCallClose';
import moment from 'moment';
import {Parser} from 'json2csv';

export const ServiceCallClose = () => {
  const authUser = useSelector(getAuthUserData);
  const dispatch = useDispatch();
  const fetchingServices = useSelector(isFailedCallsFetching);
  const hasServiceClosing = useSelector(isServiceFormForceClosed);
  const SucessForceCloseData = useSelector(hasForceCloseService);
  const [forceCloseClick, setForceCloseClick] = useState(false);
  const isForceCloseServiceRequest = useSelector(isForceClosingService);
  const [mobilityId, setMobilityId] = useState();
  const [followupCallId, setFollowupCallId] = useState('');
  const [exportClicked, setExportClicked] = useState(false);
  const failedCallsCount = useSelector(getFailedCallsCount);
  const failedCalls = useSelector(getFailedCallData);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [pageChangeClicked, setPageChangeClicked] = useState(false);
  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });
  const RefreshData = () => {
    dispatch(getFailedCallsDataCount(authUser.accessToken, 1, 10, 0, ''));
    dispatch(getFailedCallsData(authUser.accessToken, 1, 10, 0, ''));
  };

  const failedCallCsvData = useSelector(getServiceFormCloseCsvData);

  useEffect(() => {
    dispatch(getFailedCallsData(authUser.accessToken, 1, 10, 0, ''));
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    dispatch(getFailedCallsDataCount(authUser.accessToken, 1, 10, 0, ''));
  }, [dispatch, authUser.accessToken]);

  const forceClose = (value, record) => {
    dispatch(forceCloseService(record.mobilityId, authUser.accessToken));
    setForceCloseClick(!forceCloseClick);
  };
  const pageChanged = (page, pageSize) => {
    setConfig((prevState) => ({
      pagination: {
        ...prevState.pagination,
        defaultPageSize: pageSize,
        pageSize: pageSize,
      },
    }));
    setPageNumber(page);
    setPageSizeNew(pageSize);
    setPageChangeClicked(!pageChangeClicked);
  };

  useEffect(() => {
    if (forceCloseClick && SucessForceCloseData) {
      if (!isForceCloseServiceRequest) {
        message.success({
          content: 'Request Submitted successfully!!',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        });
        setForceCloseClick(!forceCloseClick);
        RefreshData();
      }
    }
  }, [forceCloseClick, isForceCloseServiceRequest, SucessForceCloseData]);

  useEffect(() => {
    if (pageChangeClicked) {
      dispatch(
        getFailedCallsData(
          authUser.accessToken,
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          mobilityId,
          followupCallId,
        ),
      );
      setPageChangeClicked(!pageChangeClicked);
    }
  }, [
    authUser.accessToken,
    dispatch,
    followupCallId,
    mobilityId,
    pageChangeClicked,
    pageNumber,
    pageSizeNew,
  ]);

  const updatedColumns = [
    ...serviceCallCloseColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'id',
      width: '12%',
      render: (text, record) => (
        <Button
          disabled={record.status !== 'Error'}
          onClick={(e) => {
            forceClose(e, record);
          }}
          style={{
            background: record.status !== 'Error' ? '#cccccc' : '#fd560eb5',
            border: record.status !== 'Error' ? '#cccccc' : '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Force Close
        </Button>
      ),
    },
    {
      title: 'Failure Date',
      dataIndex: 'createdOn',
      key: 'id',
      render: (createdOn) => {
        return <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>;
      },
      sorter: {
        compare: (a, b) =>
          moment(a.createdOn).format('DD/MM/YYYY hh:mm A') /
          moment(b.createdOn).format('DD/MM/YYYY hh:mm A'),
      },
    },
    {
      title: 'Retrial Date',
      dataIndex: 'updatedOn',
      key: 'id',
      render: (updatedOn) => {
        return <p>{moment(updatedOn).format('DD/MM/YYYY hh:mm A')}</p>;
      },
      sorter: {
        compare: (a, b) =>
          moment(a.updatedOn).format('DD/MM/YYYY hh:mm A') /
          moment(b.updatedOn).format('DD/MM/YYYY hh:mm A'),
      },
    },
  ];

  const onExportFailedCallsDataClick = () => {
    dispatch(getServiceFormClose(authUser.accessToken));
    setExportClicked(true);
  };
  useEffect(() => {
    if (failedCallCsvData?.length > 0 && exportClicked) {
      if (failedCallCsvData) {
        const headers = Object.keys(failedCallCsvData[0]);
        const parser = new Parser(headers);
        const csv = parser.parse(failedCallCsvData);
        var blob = new Blob([csv], {type: 'text/csv'});
        setExportClicked(false);
        if (window.navigator.msSaveOrOpenBlob)
          window.navigator.msSaveBlob(
            blob,
            `Failed_Service_Calls_Data_${new Date()}.csv`,
          );
        else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
          a.download = `Failed_Service_Calls_Data_${new Date()}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    }
  }, [failedCallCsvData]);
  const applyFilter = () => {
    if (mobilityId || followupCallId) {
      dispatch(
        getFailedCallsDataCount(
          authUser.accessToken,
          1,
          10,
          mobilityId ? mobilityId : 0,
          followupCallId ? followupCallId : '',
        ),
      );
      dispatch(
        getFailedCallsData(
          authUser.accessToken,
          1,
          10,
          mobilityId ? mobilityId : 0,
          followupCallId ? followupCallId : '',
        ),
      );
    }
  };
  return (
    <MainLayout>
      <div className="contentLayout">
        <Row align="middle" gutter={[16, 16]} wrap={false}>
          {/* Title */}
          <Col flex="150px">
            <span className="contentHeader">Failed Call</span>
          </Col>

          {/* Refresh Button */}
          <Col flex="120px">
            <Button
              onClick={RefreshData}
              style={{
                background: '#38a169',
                border: '#38a169',
                fontWeight: '550',
              }}
              type="primary">
              Refresh
            </Button>
          </Col>

          {/* Export Button */}
          <Col flex="220px">
            <Button
              onClick={onExportFailedCallsDataClick}
              style={{
                background: '#38a169',
                border: '#38a169',
                fontWeight: '550',
              }}
              type="primary">
              Export Failed Calls Data
            </Button>
          </Col>

          {/* Input Fields & Filter Button */}
          <Col
            flex="auto"
            style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <Input
              onChange={(e) => setMobilityId(e.target.value)}
              placeholder="Search by MobilityId"
              style={{width: '180px'}}
              value={mobilityId}
            />
            <Input
              onChange={(e) => setFollowupCallId(e.target.value)}
              placeholder="Search by FollowupCallId"
              style={{width: '200px'}}
              value={followupCallId}
            />
            <Button
              disabled={!mobilityId && !followupCallId}
              onClick={applyFilter}
              style={{
                background:
                  mobilityId || followupCallId ? '#fd560eb5' : '#cccccc',
                border: mobilityId || followupCallId ? '#fd560eb5' : '#cccccc',
                fontWeight: '550',
              }}
              type="primary">
              Filter
            </Button>
          </Col>
        </Row>

        {/* Pagination with spacing */}
        <Pagination
          current={pageNumber}
          onChange={pageChanged}
          pageSize={pageSizeNew}
          pageSizeOptions={['10', '20', '50']}
          showSizeChanger
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          total={failedCallsCount}
        />

        {/* Data Table */}
        <Table
          columns={updatedColumns}
          dataSource={failedCalls}
          loading={fetchingServices || hasServiceClosing}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
