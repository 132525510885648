import React, {useEffect, useState} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {useSelector, useDispatch} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getUserDetails, getOrgCode} from '../../modules/userDetails/action';
import {Parser} from 'json2csv';

import moment from 'moment';
import {getDashboard, getDashboardCount} from '../../modules/dashboard/action';
import {AudioOutlined} from '@ant-design/icons';
import {
  getDashboardData,
  isDashboardDataFetched,
  isDashboardDataCountFetched,
  getDashboardDataCount,
  //getDashboardCsvData,
  getDashboardTableDataCount,
} from '../../modules/dashboard/selectors';
import {
  Table,
  Space,
  Pagination,
  Card,
  Col,
  Row,
  Spin,
  Button,
  Input,
  Dropdown,
  Menu,
  DatePicker,
  Tooltip,
  Alert,
} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {activeServicesColumns} from '../../data/activeServicesColumn';
import {Doughnut, Bar} from 'react-chartjs-2';
import usePrevious from '../../hooks/usePrevious';
import ModalForm from '../common/ModalForm';
import {API_URL} from '../../constants/envVariables';
import {download} from '../../utils/downloadCsv';
import {ConsoleLogger} from '@aws-amplify/core';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const mainData = useSelector(getDashboardData);
  const isDataFetched = useSelector(isDashboardDataFetched);
  const [filterData, setFilteredData] = useState();
  const dataCountOnStatus = useSelector(getDashboardDataCount);
  const [doughnutChartData, setDoughnutChartData] = useState([]);
  const [dealerNameData, setDealerNameData] = useState([]);
  const isCountValueFetched = useSelector(isDashboardDataCountFetched);
  const prevIsDashBoardDataFetched = usePrevious(isCountValueFetched);
  const [dealerActiveData, setDealerActiveData] = useState([]);
  const [dealerCompletedData, setDealerCompletedData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [filteringCol, setFilteringCol] = useState({
    name: 'Select Filter',
    key: 'filter',
  });
  const [visible, setVisible] = useState(false);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isHorizontBarDataFetched, setIsHorizontalBarDataFetched] = useState(
    false,
  );
  const [warningText, setWarningText] = useState('');
  const [isError, setIsError] = useState(false);
  const [fallowupCallId, setFallowupCallId] = useState(' ');
  // const csvData = useSelector(getDashboardCsvData);
  const tableDataCount = useSelector(getDashboardTableDataCount);
  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50', '100'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const pageChanged = (page, pageSize) => {
    setPageNumber(page);
    setPageSizeNew(pageSize);
    dispatch(
      getDashboard(authUser.accessToken, 'Active', page, pageSize, 'All'),
    );
  };

  const {Search} = Input;
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  const onSearch = (value) => {
    setFallowupCallId(value);
    dispatch(
      getDashboard(
        authUser.accessToken,
        'Active',
        1,
        10,
        value.trim() === '' ? 'All' : value.trim(),
        //fallowupCallId.trim() === '' ? 'All' : fallowupCallId.trim(),
      ),
    );
  };

  const filterDataOnSelectedCol = (col, value) => {
    let colsplit = col.split('.');
    if (filterData && value.length > 0) {
      if (colsplit.length === 3) {
        setFilteredData(
          filterData.filter((y) =>
            (y[colsplit[0]][colsplit[1]][colsplit[2]] + '').includes(value),
          ),
        );
      } else {
        setFilteredData(
          filterData.filter((y) => (y[col] + '').includes(value)),
        );
      }
    } else {
      setFilteredData(mainData);
    }
  };
  const downloadDataDumpClick = () => {
    callApiAndDownloadCsv(
      authUser.accessToken,
      'Active',
      fallowupCallId.trim() === '' ? 'All' : fallowupCallId.trim(),
      pageNumber,
      pageSizeNew,
    );
  };

  const callApiAndDownloadCsv = async (
    token,
    status,
    followUpCallId,
    page,
    pageSize,
  ) => {
    const url = `${API_URL}/Service/GetCallsDataDownload?status=${status}&followUpCallId=${followUpCallId}&pageNumber=${page}&pageSize=${pageSize}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/csv',
      },
    })
      .then((response) =>
        response.status === 200 ? response.text() : warningSetter(),
      )
      .then((responseText) =>
        isError === false
          ? download(`ActiveServicesReport_${new Date()}.csv`, responseText)
          : '',
      )
      .catch((error) => {
        console.log(error);
      });
  };

  const warningSetter = () => {
    setWarningText('No calls data avilable for export.');
    setIsError(true);
  };

  // const downloadDataDumpClick = () => {
  //   if (csvData) {
  //     const headers = Object.keys(csvData[0]);

  //     const parser = new Parser(headers);
  //     const csv = parser.parse(csvData);
  //     var blob = new Blob([csv], {type: 'text/csv'});
  //     if (window.navigator.msSaveOrOpenBlob)
  //       window.navigator.msSaveBlob(
  //         blob,
  //         `ActiveServicesReport_${new Date()}.csv`,
  //       );
  //     else {
  //       var a = window.document.createElement('a');
  //       a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
  //       a.download = `ActiveServicesReport_${new Date()}.csv`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     }
  //   }
  // };

  useEffect(() => {
    setFilteredData(mainData);
  }, [mainData]);
  useEffect(() => {
    setDoughnutChartData({
      labels: ['Active', 'Completed'],
      datasets: [
        {
          label: 'Status',
          data: [dataCountOnStatus?.active, dataCountOnStatus?.completed],
          backgroundColor: ['#fd560eb5', '#797878'],
          borderColor: ['#fd560eb5', '#797878'],
          borderWidth: 1,
        },
      ],
    });
    if (
      dataCountOnStatus &&
      dataCountOnStatus.dealerWiseBreakUp &&
      dataCountOnStatus.dealerWiseBreakUp.length > 0 &&
      !prevIsDashBoardDataFetched &&
      isCountValueFetched
    ) {
      if (!isHorizontBarDataFetched) {
        setDealerActiveData([
          ...dealerActiveData,
          ...dataCountOnStatus.dealerWiseBreakUp.map((item) => {
            return item.active;
          }),
        ]);
        setDealerCompletedData([
          ...dealerCompletedData,
          ...dataCountOnStatus.dealerWiseBreakUp.map((item) => {
            return item.completed;
          }),
        ]);
        setDealerNameData([
          ...dealerNameData,
          ...dataCountOnStatus.dealerWiseBreakUp.map((item) => {
            return item.dealerName;
          }),
        ]);
        setIsHorizontalBarDataFetched(true);
      }
    }
  }, [
    dealerNameData,
    dealerCompletedData,
    dataCountOnStatus,
    dealerActiveData,
    isCountValueFetched,
    prevIsDashBoardDataFetched,
    isHorizontBarDataFetched,
  ]);

  useEffect(() => {
    dispatch(getUserDetails(authUser.accessToken));
    dispatch(getOrgCode(authUser.accessToken));
    var dateValue = new Date().getDate();
    var monthValue = new Date().getMonth() + 1;
    let date =
      new Date().getFullYear() +
      '-' +
      (monthValue.toString().length === 1 ? '0' + monthValue : monthValue) +
      '-' +
      (dateValue.toString().length === 1 ? '0' + dateValue : dateValue);

    dispatch(getDashboard(authUser.accessToken, 'Active', 1, 10, 'All'));
    dispatch(getDashboardCount(authUser.accessToken, date));
  }, [dispatch, authUser.accessToken]);

  const getDetails = (userData, record) => {
    setVisible(!visible);
    setModalData(record);
  };
  const updatedColumns = [
    ...activeServicesColumns,
    {
      title: 'Creation Date',
      dataIndex: ['dateCreated'],
      key: 'followupCallId',
      render: (dateCreated) => {
        return dateCreated ? (
          <p>{moment(dateCreated).format('DD/MM/YYYY hh:mm A')}</p>
        ) : (
          <p>-</p>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];

  const dropDownContent = () => {
    let filterColumn = [
      {name: 'Follow Up Call Id', key: 'followupCallId'},
      {
        name: 'Customer Call Id',
        key: 'serviceDetail.readOnlyFieldMaster.customerCallNo',
      },
      {
        name: 'Dealer Name',
        key: 'serviceDetail.readOnlyFieldMaster.serviceBranch',
      },
      {
        name: 'Call Status',
        key: 'tripStatus',
      },
      {
        name: 'Call Type',
        key: 'serviceDetail.readOnlyFieldMaster.typeOfService',
      },
      {
        name: 'Assigned Service Engineer',
        key: 'serviceDetail.readOnlyFieldMaster.serviceEngineerName',
      },
      {name: 'Equipment Number', key: 'equipmentNumber'},
    ];
    let returnable = (
      <Menu>
        {filterColumn.map((col, index) => {
          return (
            <Menu.Item
              key={String(index)}
              onClick={() => {
                setFilteringCol(col);
              }}>
              {col.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return returnable;
  };

  const handleCreate = () => {
    console.log("It won't work");
  };
  return (
    <MainLayout>
      <div className="contentLayout">
        <div className="site-card-wrapper">
          <Row gutter={16}>
            <Col span={8}>
              <Card
                bordered={false}
                title="Last 30 Days Services : Status wise breakup">
                {isCountValueFetched ? (
                  dataCountOnStatus?.active !== 0 &&
                  dataCountOnStatus?.completed !== 0 ? (
                    <Doughnut
                      data={doughnutChartData}
                      height={150}
                      options={{
                        maintainAspectRatio: false,
                      }}
                      width={200}
                    />
                  ) : (
                    <div style={{fontSize: '16pt', textAlign: 'center'}}>
                      <b>{'0'}</b>
                    </div>
                  )
                ) : (
                  <div>
                    <Spin />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={16}>
              <Card
                bordered={false}
                title="Last 30 Days Services : Dealer wise breakup">
                {dealerActiveData.length > 0 &&
                dealerNameData.length > 0 &&
                dealerCompletedData.length > 0 ? (
                  <Bar
                    data={{
                      labels: dealerNameData,
                      datasets: [
                        {
                          label: 'Active',
                          data: dealerActiveData,
                          backgroundColor: '#fd560eb5',
                        },
                        {
                          label: 'Completed',
                          data: dealerCompletedData,
                          backgroundColor: '#797878',
                        },
                      ],
                    }}
                    height={240}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            barthickness: 73,
                            stacked: true,
                          },
                        ],
                        yAxes: [
                          {
                            barthickness: 73,
                            stacked: true,
                          },
                        ],
                      },
                    }}
                    width={200}
                  />
                ) : isDataFetched ? (
                  <div style={{fontSize: '16pt', textAlign: 'center'}}>
                    <b>{'-'}</b>
                  </div>
                ) : (
                  <Spin />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className="contentLayout">
        <div>
          <Row>
            <Col span={4}>
              <span className="contentHeader">Active Services </span>
            </Col>
            <Col span={4}>
              <Button
                onClick={() => downloadDataDumpClick()}
                style={{
                  background: '#38a169',
                  border: '#38a169',
                  fontWeight: '550',
                  float: 'left',
                  marginRight: '10px',
                }}
                type="primary">
                Export Active Services
              </Button>
            </Col>
            <Col span={6}>
              <Space direction="vertical">
                <Search
                  onSearch={onSearch}
                  placeholder="Search By FollowUpCallId"
                  style={{width: 250}}
                />
              </Space>
              {/* <Input
                onChange={(e) =>
                  filterDataOnSelectedCol(filteringCol.key, e.target.value)
                }
                placeholder="Search By FollowUpCallId"
                style={{float: 'right'}}
                width="75px"
              />
              <Button
                onClick={() => downloadDataDumpClick()}
                style={{
                  background: '#38a169',
                  border: '#38a169',
                  fontWeight: '550',
                  float: 'left',
                  marginRight: '10px',
                }}
                type="primary">
                Export Active Services
              </Button> */}
            </Col>
            <Col span={9} style={{textAlign: 'center'}}>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                onChange={pageChanged}
                pageSizeOptions={[10, 20, 50, 100]}
                style={{float: 'right'}}
                total={
                  tableDataCount
                    ? tableDataCount === 0
                      ? 1
                      : tableDataCount
                    : 1
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>&nbsp;</Col>
            <Col span={4}>
              {isError === true ? (
                <Alert
                  closable
                  message={warningText}
                  onClose={() => {
                    setWarningText('');
                    setIsError(false);
                  }}
                  type="warning"
                />
              ) : null}
            </Col>
          </Row>
        </div>
        <ModalForm
          data={modalData}
          onCancel={() => setVisible(false)}
          onCreate={() => handleCreate()}
          visible={visible}
        />

        <Table
          columns={updatedColumns}
          dataSource={filterData}
          loading={!isDataFetched}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
