import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import MainLayout from '../../layouts/MainLayout';
import ServiceModal from '../common/ServiceModal';
import {activeServicesColumns} from '../../data/activeServicesColumn';
import {AudioOutlined} from '@ant-design/icons';
import {Parser} from 'json2csv';

import moment from 'moment';
import {
  Table,
  Space,
  Pagination,
  Col,
  Row,
  Button,
  Input,
  Dropdown,
  Menu,
  DatePicker,
  Tooltip,
  Alert,
} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {
  getServiceData,
  isServiceDataFetched,
  getServiceTableDataCount,
  // getDashboardCsvData,
} from '../../modules/serviceForms/selectors';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getServiceForm} from '../../modules/serviceForms/action';
import {API_URL} from '../../constants/envVariables';
import {download} from '../../utils/downloadCsv';

export const ServiceForms = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const mainData = useSelector(getServiceData);
  const isDataFetched = useSelector(isServiceDataFetched);
  const [filterData, setFilteredData] = useState();
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [filteringCol, setFilteringCol] = useState({
    name: 'Select Filter',
    key: 'filter',
  });
  const [dropDownVisible, setDropDownVisible] = useState(false);

  //const csvData = useSelector(getDashboardCsvData);
  //const csvData = [];
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [warningText, setWarningText] = useState('');
  const [isError, setIsError] = useState(false);
  const [fallowupCallId, setFallowupCallId] = useState(' ');
  const tableDataCount = useSelector(getServiceTableDataCount);
  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50', '100'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const pageChanged = (page, pageSize) => {
    setPageNumber(page);
    setPageSizeNew(pageSize);
    dispatch(
      getServiceForm(authUser.accessToken, 'Completed', page, pageSize, 'All'),
    );
  };

  const {Search} = Input;
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  useEffect(() => {
    let date =
      new Date().getFullYear() +
      '-' +
      new Date().getMonth() +
      '-' +
      new Date().getDate();
    dispatch(getServiceForm(authUser.accessToken, 'Completed', 1, 10, 'All'));
  }, [dispatch, authUser.accessToken]);
  useEffect(() => {
    setFilteredData(mainData?.filter((x) => x.status.includes('Completed')));
  }, [mainData]);

  const onSearch = (value) => {
    setFallowupCallId(value);
    dispatch(
      getServiceForm(
        authUser.accessToken,
        'Completed',
        1,
        10,
        value.trim() === '' ? 'All' : value.trim(),
        //fallowupCallId.trim() === '' ? 'All' : fallowupCallId.trim(),
      ),
    );
  };

  const getDetails = (userData, record) => {
    setVisible(!visible);
    setModalData(record);
  };
  const updatedColumns = [
    ...activeServicesColumns,
    {
      title: 'Completed Date',
      dataIndex: ['dateCreated'],
      key: 'followupCallId',
      render: (dateCreated) => {
        return dateCreated ? (
          <p>{moment(dateCreated).format('DD/MM/YYYY hh:mm A')}</p>
        ) : (
          <p>-</p>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          View Service
        </Button>
      ),
    },
  ];
  const handleCreate = () => {
    console.log("It won't work");
  };

  const filterDataOnSelectedCol = (col, value) => {
    let colsplit = col.split('.');
    if (filterData && value.length > 0) {
      if (colsplit.length === 3) {
        setFilteredData(
          filterData.filter((y) =>
            (y[colsplit[0]][colsplit[1]][colsplit[2]] + '').includes(value),
          ),
        );
      } else {
        setFilteredData(
          filterData.filter((y) => (y[col] + '').includes(value)),
        );
      }
    } else {
      setFilteredData(mainData);
    }
  };

  const downloadDataDumpClick = () => {
    callApiAndDownloadCsv(
      authUser.accessToken,
      'Completed',
      fallowupCallId.trim() === '' ? 'All' : fallowupCallId.trim(),
      pageNumber,
      pageSizeNew,
    );
  };

  const callApiAndDownloadCsv = async (
    token,
    status,
    followUpCallId,
    page,
    pageSize,
  ) => {
    const url = `${API_URL}/Service/GetCallsDataDownload?status=${status}&followUpCallId=${followUpCallId}&pageNumber=${page}&pageSize=${pageSize}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/csv',
      },
    })
      .then((response) =>
        response.status === 200 ? response.text() : warningSetter(),
      )
      .then((responseText) =>
        isError === false
          ? download(`CompletedServicesReport_${new Date()}.csv`, responseText)
          : '',
      )
      .catch((error) => {
        console.log(error);
      });
  };

  const warningSetter = () => {
    setWarningText('No calls data avilable for export.');
    setIsError(true);
  };
  // const downloadDataDumpClick = () => {
  //   if (csvData) {
  //     const headers = Object.keys(csvData[0]);

  //     const parser = new Parser(headers);
  //     const csv = parser.parse(csvData);
  //     var blob = new Blob([csv], {type: 'text/csv'});
  //     if (window.navigator.msSaveOrOpenBlob)
  //       window.navigator.msSaveBlob(
  //         blob,
  //         `CompletedServicesReport_${new Date()}.csv`,
  //       );
  //     else {
  //       var a = window.document.createElement('a');
  //       a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
  //       a.download = `CompletedServicesReport_${new Date()}.csv`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     }
  //   }
  // };

  const dropDownContent = () => {
    let filterColumn = [
      {name: 'Follow Up Call Id', key: 'followupCallId'},
      {
        name: 'Customer Call Id',
        key: 'serviceDetail.readOnlyFieldMaster.customerCallNo',
      },
      {
        name: 'Dealer Name',
        key: 'serviceDetail.readOnlyFieldMaster.serviceBranch',
      },
      {
        name: 'Call Status',
        key: 'tripStatus',
      },
      {
        name: 'Call Type',
        key: 'serviceDetail.readOnlyFieldMaster.typeOfService',
      },
      {
        name: 'Assigned Service Engineer',
        key: 'serviceDetail.readOnlyFieldMaster.serviceEngineerName',
      },
      {name: 'Equipment Number', key: 'equipmentNumber'},
    ];
    let returnable = (
      <Menu>
        {filterColumn.map((col, index) => {
          return (
            <Menu.Item
              key={String(index)}
              onClick={() => {
                setFilteringCol(col);
              }}>
              {col.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return returnable;
  };

  return (
    <MainLayout>
      <div className="contentLayout">
        <ServiceModal
          data={modalData}
          onCancel={() => {
            setVisible(false);
            setModalData([]);
          }}
          onCreate={() => handleCreate()}
          visible={visible}
        />
        <Row>
          <Col span={4}>
            <span className="contentHeader">Completed Calls</span>
          </Col>
          <Col span={4.1}>
            <Button
              onClick={() => downloadDataDumpClick()}
              style={{
                background: '#38a169',
                border: '#38a169',
                fontWeight: '550',
                float: 'left',
                marginRight: '10px',
              }}
              type="primary">
              Export Completed Services
            </Button>
          </Col>
          <Col span={5.3}>
            <Space direction="vertical">
              <Search
                onSearch={onSearch}
                placeholder="Search By FollowUpCallId"
                style={{width: 250}}
              />
            </Space>
            {/* <Input
              onChange={(e) =>
                filterDataOnSelectedCol(filteringCol.key, e.target.value)
              }
              placeholder="Search By FollowUp Call Id"
              style={{float: 'right'}}
              width="100px"
            /> */}
          </Col>
          <Col span={8} style={{textAlign: 'center'}}>
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              onChange={pageChanged}
              pageSizeOptions={[10, 20, 50, 100]}
              style={{float: 'right'}}
              total={
                tableDataCount ? (tableDataCount === 0 ? 1 : tableDataCount) : 1
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}> &nbsp;</Col>
          <Col span={4.1}>
            {isError === true ? (
              <Alert
                closable
                message={warningText}
                onClose={() => {
                  setWarningText('');
                  setIsError(false);
                }}
                type="warning"
              />
            ) : null}
          </Col>
        </Row>
        <Table
          columns={updatedColumns}
          dataSource={filterData}
          loading={!isDataFetched}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
